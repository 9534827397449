
















import { Component, Vue } from "vue-property-decorator";
import { Application } from "@/types";
import { namespace } from "vuex-class";
const ApplicationModule = namespace("ApplicationModule");
@Component({
  components: {
    DocumentViewer: () => import("@/components/DocumentViewer.vue")
  }
})
export default class DialogDocumentViewer extends Vue {
  @ApplicationModule.Getter
  application!: Application;
  private dialog = false;
}
